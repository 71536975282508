import '@src/scss/all.scss';
import util from '@src/js/util';
import $ from 'jquery';

util.init();

$(window).on('load', () => {
  const { userAgent } = navigator;

  if (userAgent.match(/AppleWebKit.*Mobile.*/)) {
    $('body').css('height', `${$(window).innerHeight()}px`);
  }
});
